"use client";
import { useEffect, useState } from "react";

import { useTranslations } from "next-intl";
import { usePathname, useRouter } from "next/navigation";

import { useDispatch, useSelector } from "react-redux";

import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

import { ToggleGroup } from "./ToggleGroup";

import { setIsLoading, setRegisterComponent, setSelectedPackagesId } from "@/store";

import { getFromLocale, setToSession } from "@/utils";

import { useParamID } from "@/hooks";
import { HTMLWrapper } from "./HtmlWrapper";

import { AosAnimation } from "@/constants";


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const currency_icon = {
    'azn': "₼",
    'usd': "$"
}

const Packages = ({ lang, default_currency, currency, site_name, data, inProgress = false }) => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const access_token = getFromLocale()
    const location = usePathname()
    const router = useRouter();
    const template_id = useParamID('template_id')
    const package_id = useParamID('package_id')

    const [activeId, setActiveId] = useState(1);

    function convertCurrency(amount) {
        const exchangeRates = {
            'azn': {
                'usd': 1 / 1.7
            },
            'usd': {
                'azn': 1.7
            }
        };
        if (!(default_currency in exchangeRates) || !(currency in exchangeRates[default_currency])) {
            return `${currency_icon[currency]}${amount}`
        }
        return `${currency_icon[currency]}${(amount * exchangeRates[default_currency][currency]).toFixed(2)}`;
    }

    const active = (id) => {
        setActiveId(id);
    };

    const packages = data?.map(function (item) {
        let filteredItem = {};
        filteredItem = { ...item };
        filteredItem.prices = item.prices.find(function (price) {
            return price.our_domain == site_name;
        });
        return filteredItem;
    });
    const frequencies = [
        { id: "monthly", text: t("monthly"), priceSuffix: `/${t("month")}` },
        { id: "annually", text: t("annually"), priceSuffix: `/${t("year")}` },
    ];

    const {
        formType,
        formType: { packageFrequency },
    } = useSelector((state) => state.common);


    const suffixHandler = () =>
        frequencies.find((item) => item.id === formType["packageFrequency"])
            ?.priceSuffix;

    useEffect(() => {
        if (data?.length > 0) {
            setActiveId(packages[1]?.id);
        }
    }, [data]);


    const selectPackages = async (id, amount, name) => {
        dispatch(setIsLoading(true));
        try {
            const totalAmount = packageFrequency !== 'annually' ? amount : Math.round(amount * 12);
            setToSession({ value: totalAmount, key: 'amount' });
            setToSession({ value: packageFrequency, key: 'package_type' });
            setToSession({ value: id, key: 'package_id' });
            setToSession({ value: name, key: 'package_name' });
            dispatch(setSelectedPackagesId(id));

            if (!location.startsWith('/website')) {
                return router.push('/website');
            }

            if (!access_token) {
                dispatch(setRegisterComponent("sign-in"));
                return router.push(`/website?step=2&template_id=${template_id}&path=sign-in`);
            }

            if (access_token) {
                return router.push(`/website?step=3&template_id=${template_id}&package_id=${id}&path=confirmation`);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            dispatch(setIsLoading(false));
        }
    }

    const handleBold = (feature) => {
        const [title, description] = feature.split(":");
        const htmlString = `<b>${title}${description ? ":" : ""}</b> ${description || ''}`
        return <HTMLWrapper htmlString={htmlString} />
    }
    const [openAllFeatures, setOpenAllFeatures] = useState(false)
    return (
        <div className={`bg-white ${!inProgress ? 'md:mt-10 py-16' : 'mt-2'} `} {...AosAnimation.Packages}>
            <div className="mx-auto max-w-7xl text-center">
                <div className="flex  flex-col md:flex-row items-center justify-between px-12 mb-16">
                    <div className="lg:w-1/2 flex flex-col max-w-[1296px]  gap-8">
                        {!inProgress && <span className="sm:text-[42px] sm:leading-[44px] tracking-[0.1px] text-mainDarkPurple md:text-[32px] leading-[38px] font-bold text-start">
                            {t("priceSectionTitle")}
                        </span>}
                        <span className="sm:text-base sm:tracking-[0.1px] sm:font-medium text-mainLightPurpleText text-xl text-start">
                            {t("priceSectionDesc")}
                        </span>
                    </div>
                    <div className="lg:w-1/2 pt-10">
                        <ToggleGroup toggleItems={frequencies} isCenter={false} sm type="packageFrequency" />
                    </div>
                </div>
                <div className="isolate relative mx-auto grid p-4 grid-cols-1 lmd:gap-3 gap-4 lsm:grid-cols-1 xmd:grid-cols-2 lmd:grid-cols-3 lg:mx-0 lg:max-w-none">
                    {packages?.map(
                        (
                            { id, name, features, prices },
                            idx
                        ) => {
                            return (
                                <div onClick={() => selectPackages(id, prices?.prices, name)}
                                    key={id} className={`${idx == 1 ? 'lmd:top-2 top-0 lmd:scale-105 scale-100 z-20' : ''} relative`}>
                                    {idx == 1 ? (
                                        <div className="absolute right-4 top-4 text-xs px-3 py-2 bg-white rounded-2xl font-bold text-mainLightPurple">
                                            <span> {t("mostPopular")} </span>
                                        </div>
                                    ) : null}
                                    <div
                                        className={classNames(
                                            activeId == id
                                                ? "ring-2 ring-mainDarkPurpleRing shadow-xl shadow-mainDarkPurpleRing/50"
                                                : "ring-1 ring-gray-100 shadow-md",
                                            "rounded-lg sm:p-6 md:p-4 cursor-pointer hover:ring-2 hover:ring-mainDarkPurpleRing transition bg-[#FAFBFF] shadow-xl "
                                        )}
                                        onClick={() => active(id)}
                                    >

                                        <div className="mt-2 flex flex-col gap-x-1 px-1">
                                            <div className="flex px-1">
                                                <div className="text-4xl font-bold tracking-tight text-mainDarkPurple">
                                                    {packageFrequency == frequencies[0]?.id ? convertCurrency(prices?.prices) : convertCurrency(Math.round(prices.prices * 12))}

                                                </div>
                                                <div className="text-sm font-semibold leading-[3rem] text-mainDarkPurple ml-2 mt-1">
                                                    {suffixHandler()}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bg-white px-2 py-1 rounded-xl">
                                            <div className="flex items-center px-1 gap-x-2 relative">
                                                <div id={id} className={classNames(activeId == id ? "text-mainDarkPurple" : "text-mainDarkPurple", "text-2xl font-bold leading-8 text-left")} >
                                                    {name}
                                                </div>
                                            </div>
                                            <ul
                                                role="list"
                                                className="mt-3 xl:mt-5 space-y-2 text-sm leading-6 text-mainDarkPurpleText "
                                            >
                                                {features.map(
                                                    ({ name }, idx) => {
                                                        if ((!openAllFeatures && ((id == 4 && idx > 9) || (id == 3 && idx > 8) || (id != 4 && idx > 9)))) return
                                                        return (
                                                            <li key={idx} className="flex gap-x-3">
                                                                <CheckIcon
                                                                    className="h-6 w-5 flex-none text-mainGreen"
                                                                    aria-hidden="true"
                                                                />
                                                                <div className="text-left">
                                                                    {handleBold(name[lang])}
                                                                </div>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                            <div className="text-mainLightPurple font-semibold flex items-center justify-center mt-6 select-none" onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenAllFeatures(!openAllFeatures);
                                            }}>
                                                {openAllFeatures ? t("seeLess") : t("seeAll")}
                                                <ChevronDownIcon className={`text-mainLightPurple w-6 h-6 ${openAllFeatures ? "rotate-180" : ""}`} />
                                            </div>
                                        </div>
                                        <div
                                            aria-describedby={id}

                                            className={classNames(
                                                activeId == id
                                                    ? "bg-mainLightPurple text-white shadow-sm hover:bg-white hover:text-mainLightPurple "
                                                    : "text-mainLightPurple bg-white  hover:ring-blue-300 hover:bg-mainLightPurple hover:text-white",
                                                "mt-6 block rounded-3xl py-2 px-3 text-center text-sm font-semibold leading-6 border border-mainLightPurple transition-all duration-200"
                                            )}
                                        >
                                            {t("buyPlan")}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};

export default Packages;
