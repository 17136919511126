"use client"

import Link from "next/link"
import Image from "next/image"

import { AosAnimation } from "@/constants";

import { useTranslations } from "next-intl"

import { FaCheck } from "react-icons/fa"

const currency_icon = {
    'azn': "₼",
    'usd': "$"
}

const HeroHeaderBanner = ({ lang, default_currency, currency }) => {
    const t = useTranslations()
    const amount = 10;
    const benefits = [
        t('banner_1'),
        t('banner_2'),
        t('banner_3'),
    ];
    function convertCurrency(amount) {

        const exchangeRates = {
            'azn': {
                'usd': 1 / 1.7
            },
            'usd': {
                'azn': 1.7
            }

        };

        if (!(default_currency in exchangeRates) || !(currency in exchangeRates[default_currency])) {

            return {
                integerPart: Math.floor(amount),
                fractionalPart: (amount % 1).toFixed(2).substring(1)
            };
            
        }
    
        const convertedAmount = (amount * exchangeRates[default_currency][currency]).toFixed(2);

        const [integerPart, fractionalPart] = convertedAmount.split('.');
    
        return {
            integerPart: integerPart,
            fractionalPart: `.${fractionalPart}`
        };
    }
  

    const { integerPart, fractionalPart } = convertCurrency(amount);    

    return (
        <div className="max-w-[1472px] lg:max-w-[1208px] 2xl:px-10 xl2:px-20 mx-auto md:px-10 px-4 pb-10 overflow-hidden font-dmsans pt-14" {...AosAnimation.HeroHeaderBanner}>
            <div className="flex items-center flex-col lg:flex-row gap-y-6 justify-between overflow-hidden">
                <div className="w-full lg:w-4/12 flex lg:block flex-col items-center flex-shrink-0">
                    <p className="text-lg md:text-xl font-bold mb-2 text-[#300B60]">{lang === 'en' ? (
                        <>Expert <span className="text-mainLightPurple">24/7</span> Support</>
                    ) : (
                        <> <span className="text-mainLightPurple">24/7</span> Texniki Dəstək</>
                    )}</p>
                    <h1 className="text-2xl md:text-4xl font-bold mb-6 whitespace-nowrap md:whitespace-normal text-[#300B60]">{t('createWebSite')} </h1>
                    <ul className="text-[#2f1c6a] mb-6">
                        {benefits.map(item => <li key={item} className="flex gap-x-2.5 items-center">
                            <FaCheck className="w-3 text-[#00B090] inline-block" />
                            <span className="text-[#4A2182]">{item}</span>
                        </li>)}
                    </ul>
                    <h3 className="text-[#300B60] text-lg leading-10 md:leading-[56px]">
                        <span>{currency_icon[currency]}</span>
                        <span className="text-4xl md:text-5xl font-bold">
                            {integerPart}
                            <span className="text-sm md:text-lg font-normal">{fractionalPart}</span>
                        </span>
                        <span>/{t('shortMonth')}</span>
                    </h3>
                    <span className="text-[#2f1c6a] font-bold text-lg mb-6 inline-block">+ {t('freeMonth')}</span>
                    <div className="flex gap-3 flex-col md:flex-row">
                        <Link className="bg-mainLightPurple text-white rounded-lg py-3 px-12 inline-block font-bold hover:bg-mainLightPurpleHover whitespace-nowrap" href={'/website'}>{t('startLink')}</Link>
                    </div>
                    <div className="text-[#300B60] mt-3 leading-6 flex items-center pl-5">{t('guarantee')}</div>
                </div>
                <div className="w-full lg:w-8/12 max-w-[880px]">
                    <div className="max-w-full flex-shrink-0">
                        <Image src="/static/hero-banner.webp" className="w-full object-cover flex-shrink-0" width={762} height={381} alt="hero" priority />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroHeaderBanner