"use client";
import Image from "next/image";
import Link from "next/link";
import { Loader } from "./Loader";
import { Pagination } from "./Pagination";

export const BlogList = ({ showPagination = true, listCount, data, lang, total, page }) => {
  const itemCount = listCount || (data?.length || 0);
  if (!data && !listCount) {
    return <Loader fullscreen={false} />;
  }
  return (
    <>
      <div className="mb-24 sm:p-4 mx-auto max-w-[1296px] h-full grid md:grid-cols-1 lmd:grid-cols-2 xl:grid-cols-3 gap-6">
        {!!data &&
          data?.slice(0, itemCount).map(({ id, image, created_at, title, slug }) => (
            <Link
              key={id}
              // href={`/blog/${slug}`}
              href={{
                pathname: `/blog/details`,
                query: { name: slug },
              }}
              className="blog-post bg-mainBgPurple relative py-2 md:min-w-[414px] px-2 pt-2 rounded-[7px] transform hover:shadow-lg hover:border-mainLightPurpleActive transition duration-200 ease-in border-[2px] border-solid border-mainLightPurple cursor-pointer"
            >
              <div className="">
                {image ? (
                  <Image
                    className="w-full h-full object-contain aspect-[16/9] rounded-[7px] border border-solid border-slate-200"
                    src={`${image}`}
                    width={1296}
                    height={256}
                    alt="Blog Post"
                  />
                ) : (
                  ""
                )}
              </div>
              <h2 className="text-xl font-medium mt-[30px] mb-[24px] text-slate-700 min-h-[56px] line-clamp-2">
                {title}
              </h2>
              <div className="flex flex-wrap gap-[8px] text-base min-h-[34px] self-end">
                <span className="text-white border rounded-[8px] leading-[24px] bg-auto flex justify-center items-center bg-mainLightPurple px-4 h-[32px]">
                  {created_at}
                </span>
              </div>
            </Link>
          )
          )}
      </div>
      {showPagination && total ? (
        <Pagination
          {...{
            total,
            currentPage: page,
          }}
        />
      ) : null}
    </>
  );
};
